@import "styles/font.scss";
@import "styles/consts.scss";
@import "styles/transition.scss";
@import "styles/editor.scss";
@import "styles/tooltip.scss";
@import "styles/shutter.scss";
@import "styles/highlighter.scss";
@import "styles/help.scss";

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;

  z-index: 1;
}

@mixin rst-input {
  background: transparent;
  border: none;
  outline: none;
  padding: 0;
}

@mixin centering {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Components */
.icon {
  user-select: none;
}

/* Frame */

.container {
  background: $bg;
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;

  background: $bg;
  transition: opacity .5s ease-out;

  overflow: hidden;

  &.pending {
    opacity: 0;
    align-items: center;
    justify-content: center;
  }
}

header {
  height: 60px;

  display: flex;
  align-items: center;

  z-index: 100;

  position: relative;

  .spanner {
    background: $bg;
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    box-shadow: rgba(0,0,0,.3) 0 4px 12px;
    z-index: 90;
    pointer-events: none;
  }

  .brand {
    font-size: 18px;
    color: rgba(255,255,255,.5);
    font-weight: 100;

    user-select: none;

    strong {
      color: rgba(255,255,255,.7);
      font-weight: 700;
    }

    line-height: 24px;
    padding-left: 20px;
    padding-right: 30px;
    height: 60px;
    background: mix(white, $bg, 10%);
    display: inline-flex;
    align-items: center;
  }

  .stub {
    height: 100%;
    font-size: 18px;
    color: white;

    display: flex;
    align-items: center;
    padding-right: 10px;
    padding-left: 30px;
    margin-left: -20px;
    z-index: 1;
    background: $bg;

    clip-path: polygon(20px 0%, 100% 0%, 100% 100%, 0% 100%);

    cursor: pointer;

    .stub-caret {
      display: inline-block;
      border: transparent 5px solid;
      border-top: white 5px solid;
      margin-top: 5px;
      margin-left: 10px;

      opacity: .5;
      transition: opacity .2s ease;
    }

    &:hover .stub-caret {
      opacity: 1;
    }

    &.stub-disabled {
      cursor: default;
      pointer-events: none;

      .stub-caret {
        opacity: 0;
      }
    }
  }

  .shifter {
    transition: transform .5s ease-out;
    display: inline-flex;

    &.shifted {
      transform: translateX(100%);
      transition: transform .5s ease-in;
    }

    .logout {
      padding: 0 20px 0 30px;
      height: 60px;
      display: flex;
      align-items: center;
      clip-path: polygon(20px 0%, 100% 0%, 100% 100%, 0% 100%);
      background: mix(white, $bg, 10%);

      cursor: pointer;

      .material-icons {
        color: white;
        opacity: .7;
        transition: opacity .2s ease;
      }

      &:hover .material-icons {
        opacity: 1;
      }
    }

    .latest-build {
      color: white;
      padding: 0 30px;
      margin-right: -20px;
      display: inline-flex;
      flex-direction: column;
      justify-content: center;

      user-select: none;

      background: $bg;

      .latest-build-hint {
        color: white;
        font-size: 12px;
        line-height: 12px;

        display: inline-flex;
        justify-content: flex-end;
        font-weight: 100;

        opacity: .38;

        transform: translate(5px);
        transition: transform .2s ease, opacity .2s ease;
      }

      .latest-build-info {
        margin-top: 2px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
      }

      .latest-build-id {
        @extend .monospace;
        font-size: 24px;
        line-height: 24px;

        small {
          opacity: .54;
        }
      }

      .latest-build-status {
        margin-left: 5px;
        height: 24px;

        > .material-icons {
          font-size: 24px;
          display: inline-block;
          line-height: 24px;
          width: 24px;
          height: 24px;
        }
      }

      .latest-build-pending {
        width: 14px;
        height: 14px;
        margin: (24px-14px)/2;
        box-sizing: border-box;
        border: change-color($primary-color, $alpha: 0.7) 2px solid;

        @keyframes pending-rotate {
          0% {
            transform: rotate(0deg);
          }

          25% {
            transform: rotate(90deg);
          }

          50% {
            transform: rotate(180deg);
          }

          75% {
            transform: rotate(270deg);
          }

          100% {
            transform: rotate(360deg);
          }
        }

        animation: pending-rotate 4s infinite;
      }

      .latest-build-success {
        color: #1B813E;
      }

      .latest-build-failed {
        color: $error-color;
      }

      .latest-build-aux {
        font-size: 12px;
        line-height: 12px;

        transform: translate(-5px);
        transition: transform .2s ease;
      }

      .latest-build-nonsense {
        width: 5px;
        opacity: 0;

        transition: opacity .2s ease;
      }

      &:hover {
        .latest-build-nonsense {
          opacity: 1;
        }

        .latest-build-hint {
          transform: none;
          opacity: 1;
        }

        .latest-build-aux {
          transform: none;
        }

        .build-list {
          transform: none;
        }
      }
    }
  }
}

.build-list {
  position: absolute;
  top: 0;
  right: 0;

  width: 100vw;
  max-width: 400px;

  height: 300px;
  padding: 70px 20px 10px 20px;
  background: $bg;
  overflow-y: auto;

  z-index: -1;
  box-shadow: rgba(0,0,0,.3) 0 2px 6px;

  transform: translateY(-100%);
  transition: transform .5s ease;

  .build-list-entry {
    display: flex;
    height: 40px;
    align-items: center;

    .build-list-sep {
      @extend .sep;
    }

    .build-list-id {
      @extend .monospace;
      line-height: 40px;
      font-size: 28px;

      small {
        opacity: .54;
      }
    }

    .build-list-status {
      line-height: 16px;
      font-size: 12px;
      flex: 1;

      color: rgba(255,255,255,.7);

      // TODO: investigate how to highlight status lines in build lists with proper color contrast
      /*
      &.build-list-status-error {
        color: $error-color;
      }
      */
    }

    &.build-list-pending .build-list-status {
      @keyframes pending-status {
        0% {
          opacity: 0.7;
        }

        50% {
          opacity: .3;
        }
      }

      animation: pending-status 2s infinite ease;
    }

    .build-list-action {
      color: white;
      opacity: .7;

      cursor: pointer;
      transition: opacity .2s ease;

      &:hover {
        opacity: 1;
      }

      &.build-list-action-disabled {
        cursor: default;
        opacity: 0.38;

        &:hover {
          opacity: 0.38;
        }
      }
    }
  }

  .build-list-placeholder {
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: center;
    color: rgba(255, 255, 255, .18);

    @extend .monospace;
    font-size: 18px;
    text-align: center;
    padding: 20px;
  }

  .build-list-end {
    margin-top: 10px;
    color: white;
    text-align: center;

    cursor: pointer;

    opacity: .38;
    transition: opacity .2s ease;

    &:hover {
      opacity: .7;
    }

    &.build-list-frozen {
      cursor: normal;

      &:hover {
        opacity: .7;
      }
    }
  }
}

.spanner {
  flex: 1;
  height: 100%;
}

main {
  flex: 1;

  &.centering {
    @include centering;
  }
}

/* Input */
.input {
  position: relative;

  .input-label {
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    font-size: 12px;
    color: rgba(255,255,255,.38);
    line-height: 28px;
    padding: 0 16px;
  }

  input {
    @include rst-input;
    line-height: 20px;
    font-size: 18px;
    padding: 24px 16px 10px 16px;
    background: rgba(255,255,255,.1);
    color: white;
    width: 100%;
  }
}

/* Login */
.login-box {
  position: relative;
  padding-right: 128px + 20px;

  .login-input {
    width: 240px;
  }

  .login-spanner {
    height: 20px;
  }

  .login-button {
    @include rst-input; 
    position: absolute;
    width: 128px;
    height: 128px;
    right: 0;
    top: 0;
    background: $primary-color;

    display: inline-flex;
    align-items: center;
    justify-content: center;
    filter: grayscale(1);
    transition: filter .4s ease;

    &:not(:disabled) {
      cursor: pointer;
      filter: none;
    }

    &.errored {
      filter: hue-rotate(360deg-(hue($primary-color)));
    }

    .login-icon {
      font-size: 60px;
      color: white;
      opacity: .7;
      transition: opacity .2s ease;
    }

    &:hover:not(:disabled) .login-icon {
      opacity: 1;
    }
  }
}

.material-icons {
  user-select: none;
}

.workspace {
  display: flex;

  > .left {
    flex: 1;
    overflow: hidden;
    margin-right: -60px;
    min-width: 0;
    position: relative;
  }

  > .toolbar {
    z-index: 10;
    width: 60px;
    backdrop-filter: blur(4px);
    box-shadow: rgba(0,0,0,.3) 0 4px 12px;
    button {
      @include rst-input;
      width: 60px;
      height: 60px;
      color: white;

      background: rgba(255,255,255,.1);

      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;

      transition: filter .2s ease;

      &:disabled {
        cursor: default;
        filter: grayscale(1);
        .material-icons {
          opacity: .38;
        }
      }

      &.primary {
        background: $primary-color;
      }

      .material-icons {
        opacity: .7;
        transition: opacity .2s ease;
      }

      &:hover:not(:disabled) .material-icons {
        opacity: 1;
      }
    }
  }

  > .right {
    flex: 1;
    z-index: 10;
    min-width: 0;
  }
}

.sandbox {
  height: 100%;
  position: relative;
  cursor: move;
  outline: 0;
}

.block {
  width: 170px;
  height: 170px;
  background: #666;
  box-shadow: rgba(0,0,0,.3) 0 2px 6px;
  user-select: none;
}

.block-wrapper {
  cursor: grab;
  position: absolute;

  .block {
    position: relative;
  }

  .block-ops {
    position: absolute;
    top: 0;
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: flex-end;

    button {
      @include rst-input;
      cursor: pointer;

      height: 20px;
      width: 20px;

      .material-icons {
        font-size: 14px;
        line-height: 14px;
      }

      background: rgba(255,255,255,.1);
      margin: 0;
      display: inline-flex;
      align-items: center;
      justify-content: center;

      &.delete {
        background: #9e1616;
        color: white;
      }

      .material-icons {
        opacity: .7;
        transition: opacity .2s ease;
      }

      &:hover .material-icons {
        opacity: 1;
      }
    }

    transition: transform .2s ease;
  }

  z-index: 1;

  &:hover {
    z-index: 2;
  }

  &:hover .block-ops {
    transform: translateY(-20px);
  }
}

.connector {
  cursor: pointer;
  width: 15px;
  height: 15px;
  border-radius: 10px;
  border: rgba(0,0,0,.3) 5px solid;
  box-shadow: rgba(0,0,0,.1) 0 1px 3px;

  // transition: border-color .2s ease;

  &.clocking {
    border-color: mix($primary-color, #333);
  }
}

.switch4 {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 10px;

  .switches, .leds {
    flex: 1;
    width: 100%;
    display: flex;
    align-items: center;

    > div {
      flex: 1;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }
}

.fpga {
  position: relative;

  display: grid;
  padding: 10px;
  // 6*7 grid
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  user-select: none;

  .pin-group {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    .pin {
      position: relative;
      z-index: 2;
    }

    .label {
      @extend .monospace;
      position: absolute;
      z-index: 1;
      right: -2px;
      bottom: -4px;
      font-size: 12px;
      width: 100%;
      text-align: right;
      font-weight: 900;
      transform: scale(0.8);
      color: rgba(0,0,0,.38);
    }
  }

  .fpga-mask-anchor {
    width: 100%;
    height: 100%;
    position: absolute;
  }

  .fpga-mask {
    width: 100%;
    height: 100%;
    position: absolute;
    padding: 20px;

    background: rgba(0,0,0,.3);
    z-index: 3;

    color: white;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    cursor: pointer;

    .fpga-mask-hint {
      font-size: 14px;
      opacity: .7;
    }

    .material-icons {
      flex: 1;
      display: inline-flex;
      align-items: center;

      font-size: 72px;
      opacity: .38;
      transition: opacity .2s ease;
    }

    &:hover .material-icons {
      opacity: .87;
    }
  }
  
  .fpga-ident {
    @extend .monospace;
    font-size: 12px;
    transform: scale(.8);
    position: absolute;
    transform-origin: right bottom;
    bottom: 0;
    right: 0;
    color: rgba(0,0,0,.38);
    padding: 5px 10px;
    background: rgba(0,0,0,.1);
    line-height: 12px;
    font-weight: 900;

    display: flex;
    align-items: center;
  }
}

.digit4 {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 10px;

  .pins, .digits {
    flex: 1;
    width: 100%;
    display: flex;
    align-items: center;

    > div {
      flex: 1;
      height: 100%;
      padding-top: 3px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
    }

  }

  .pins {
    display: grid;
    padding-top: 10px;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr;
  }

  .digit {
    font-family: 'Segment7Standard';
    font-size: 30px;
  }
}

.digit7 {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 4px;

  .pins, .digits {
    flex: 1;
    width: 100%;
    display: flex;
    align-items: center;

    > div {
      flex: 1;
      height: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
    }

  }

  .pins {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  }

  .digit {
    font-family: 'Segment7Standard';
    font-size: 30px;
  }
}

.clock {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 10px;

  .clocks {
    width: 100%;
    display: inline-flex;
    align-items: flex-start;
    justify-content: center;
  }

  .clock-box {
    flex: 1;
    display: flex;
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .clock-label {
    @extend .monospace;
    font-weight: 900;
    color: rgba(0,0,0,.7);
    margin-top: 5px;
    text-orientation: sideways;
    writing-mode: vertical-lr;

    small {
      color: rgba(0,0,0,.38);
    }
  }

  .clock-top {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  .clock-bottom {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    > div {
      padding-left: 20px;
      padding-right: 20px;
    }

    .press-btn {
      height: 26px;
      width: 13px;
      background: rgba(255,255,255,.2);
      cursor: pointer;
      margin: 5px;
      
      transition: transform .2s ease, box-shadow .2s ease;
      box-shadow: rgba(0,0,0,.3) 0 3px 9px;

      &.pusheen {
        transform: scale(0.8);
        box-shadow: rgba(0,0,0,.3) 0 1px 3px;
      }
    }
  }
}

.led {
  width: 10px;
  height: 20px;
  border-top: white 2px solid;
  border-bottom: white 4px solid;
  border-left: white 2px solid;
  border-right: white 2px solid;

  box-shadow: rgba(0,0,0,.3) 0 2px 6px;

  background: white;

  margin: 10px;

  transition: background .2s ease, box-shadow .2s ease;

  &.lighten {
    background: #22ea22;
    box-shadow: rgba(0,0,0,.3) 0 2px 6px, #22ea22 0 0 4px;
  }
}

.switch {
  width: 20px;
  height: 20px;
  margin: 10px 0;
  cursor: pointer;
  box-shadow: rgba(0,0,0,.3) 0 3px 9px;
  background: rgba(255,255,255,.2);

  transition: box-shadow .2s ease, background .2s ease;

  &.pusheen {
    box-shadow: rgba(0,0,0,.3) 0 1px 3px;
    background: rgba(255,255,255,.05);
  }
}

.wires {
  width: 100%;
  height: 100%;
  pointer-events: none;
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  top: 0;

  z-index: 4;

  opacity: .5;

  transition: opacity .5s ease;

  &.wires-shown {
    opacity: 1;
    pointer-events: all;
  }

  &.wires-off {
    opacity: 0;
    transition-duration: .1s;
  }

  &.wires-hover {
    cursor: pointer;
  }
}

.hover-connectors {
  width: 100%;
  height: 100%;
  pointer-events: none;
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  top: 0;

  z-index: 5;
  pointer-events: none;

  opacity: 0;
  transition: opacity .5s ease;

  &.hover-connectors-shown {
    opacity: 1;
  }
}

.ctx {
  position: fixed;
  box-shadow: rgba(0,0,0,.3) 0 2px 6px;
  backdrop-filter: blur(4px);
  background-color: change-color(#333, $alpha: .7);
  z-index: 100;

  .ctx-entry {
    width: 150px;
    padding: 0 20px;
    line-height: 40px;
    font-size: 14px;
    color: rgba(255,255,255,.7);
    cursor: pointer;
    transition: color .2s ease;

    &:hover {
      color: rgba(255,255,255,1);
    }
  }
}

.backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0,0,0,.3);
  z-index: 1000;

  &.centering {
    @include centering;
  }
}

.hint {
  color: rgba(255,255,255,.54);
  font-weight: 200;
  font-size: 14px;
  line-height: 18px;
}

.dimmed {
  opacity: .54;
}

.dialog {
  width: 400px;
  background: $bg;
  padding: 20px 30px;
  box-shadow: rgba(0,0,0,.3) 0 4px 12px;

  .dialog-title {
    margin: 0;
    color: white;
    font-size: 24px;
    line-height: 32px;
    font-weight: bold;

    margin-bottom: 20px;
  }
}

.search-box {
  height: 42px;
  position: relative;

  .material-icons {
    color: rgba(255,255,255,.54);
    font-size: 24px;
    line-height: 40px;

    position: absolute;
    left: 0;
    top: 0;
  }

  .search-input {
    @include rst-input;
    color: white;
    font-size: 18px;
    line-height: 40px;
    width: 100%;
    height: 100%;
    padding-left: 24px + 10px;

    border-bottom: rgba(255,255,255,.38) 2px dashed;
  }
}

.subscript-input-region {
  @include rst-input;
  display: inline-block;
  width: 2em;
  text-align: center;

  font-size: inherit;
  color: inherit;
  line-height: inherit;
  font-weight: inherit;
  font-family: inherit;

  position: relative;

  border-bottom: rgba(255,255,255,.38) 2px dashed;
  transition: border-color .2s ease; // TODO: performance concern?

  overflow: hidden;
  text-overflow: ellipsis;

  &.invalid {
    border-color: $error-color;
  }
}

.material-icons.subscript-adjust {
  vertical-align: top;

  line-height: 32px;
  font-size: 24px;

  color: white;
  opacity: .38;

  transition: opacity .2s ease;

  &:not(.invalid) {
    cursor: pointer;
    &:hover {
      opacity: 1;
    }
  }
}

.pin-selector {
  margin-top: 20px;
  height: 300px;
  overflow-y: auto;
  box-shadow: rgba(0,0,0,.3) 0 2px 6px inset;
  padding: 10px;

  background: rgba(0, 0, 0, .2);

  .pin {
    box-shadow: rgba(0,0,0,.3) 0 1px 3px;

    height: 80px;

    display: flex;

    cursor: pointer;
    color: white;

    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }

    background: $bg;

    .pin-ident {
      width: 80px;
      background: $primary-color;

      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .pin-number {
        font-size: 32px;
        font-weight: bold;
        color: white;
      }

      .pin-name {
        font-size: 12px;
        color: rgba(255, 255, 255, .38);
      }
    }

    .pin-info {
      flex: 1;
      padding: 10px;

      display: flex;
      flex-direction: column;

      .pin-assignment {
        flex: 1;
        font-size: 18px;
        opacity: .54;
      }

      .pin-direction {
        font-size: 12px;
        color: rgba(255, 255, 255, .54);
        font-weight: 100;
      }
    }

    .pin-enter-hint {
      align-self: flex-end;
      margin-right: 10px;
      color: rgba(255, 255, 255, .38);
      font-size: 12px;
      line-height: 16px;
      height: 16px;
      margin-bottom: 10px;

      .material-icons {
        font-size: 18px;
        line-height: 16px;
      }
    }

    &.pin-assigned {
      .pin-ident {
        background: rgba(255,255,255, .05);
      }

      .pin-assignment {
        opacity: 1;
      }
    }
  }
}

.sandbox-toolbar {
  $palette-height: 25px;

  position: absolute;
  bottom: 0;
  left: 0;
  height: 48px + 20px + $palette-height;
  width: 350px;
  padding: 10px;
  background: #121212;

  box-shadow: rgba(0,0,0,.3) 0 2px 6px;
  border-left: $primary-color 5px solid;

  display: inline-flex;
  align-items: flex-start;

  z-index: 6; // 4-5 is for wire-layer

  background-image: linear-gradient(90deg, change-color($primary-color, $alpha: .3) 0px (48px+10px*2), transparent (48px+10px*2) 100%);

  .layer-switcher {
    $ratio: 0.5;

    pointer-events: all;
    cursor: pointer;
    height: 48px;
    width: 48px * (1+$ratio) + 20px;
    position: relative;

    .layer-icon {
      color: white;
      font-size: 48px;

      position: absolute;
      left: 0;
      top: 0;

      transform-origin: top left;
      transform: translate(48px + 20px, 0) scale($ratio);
      opacity: .54;
      transition: transform ease .2s, opacity ease .2s;

      &.layer-icon-active {
        transform: none;
        opacity: 1;
      }
    }

    &:hover .layer-icon {
      opacity: 1;
    }
  }

  .sep {
    height: 24px;
    height: 24px;
  }

  $move: 10px;
  $stagger: .1s;
  $interval: .05s;

  $block-tool-count: 1;

  .tool {
    color: white;
    margin-right: 5px;
    cursor: pointer;

    z-index: 1;
    pointer-events: none;

    .material-icons {
      transform: translateY($move);
      opacity: 0;

      transition-property: transform, opacity;
      transition-timing-function: ease;
      transition-duration: .2s;
    }

    @for $i from 1 through 10 {
      &[data-tool~="#{$i}"] {
        .material-icons {
          transition-delay: ($i - 1) * $stagger;
        }
      }
    }

    &.tool[data-tool="wire 1"] {
      margin-left: -$block-tool-count*(5px + 24px);
    }

    transition: opacity .2s ease;

    opacity: .54;

    &:hover {
      opacity: 1;

      & + .tool-activated {
        opacity: 1;
      }
    }

    &.tool-disabled {
      cursor: default;
      opacity: .18;
    }

    &.tool-last {
      margin-right: 0;
    }
  }

  @mixin tool-shown {
    pointer-events: all;

    .material-icons {
      transform: none;
      opacity: 1;
    }

    @for $i from 1 through 10 {
      &[data-tool~="#{$i}"] .material-icons {
        transition-delay: .2s + $interval + ($i - 1) * $stagger;
      }
    }
  }

  &.block-mode {
    .tool[data-tool~="block"] {
      @include tool-shown;
    }
  }

  &.wire-mode {
    .tool[data-tool~="wire"] {
      @include tool-shown;
    }
  }

  .sandbox-toolbar-hint {
    position: absolute;
    bottom: $palette-height;
    left: 48px + 20px;
    right: 0;

    height: 24px;
    padding: 0 10px;
    line-height: 24px;
    font-size: 12px;
    @extend .monospace;
    color: white;
    background: #333;

    user-select: none;

    z-index: 0;

    &.tool-activated {
      z-index: 2;
      opacity: 0;
      transition: opacity .2s ease;
    }
  }

  .layer-hint {
    position: absolute;

    transition: opacity .5s ease, transform .5s ease;

    $shift: 15px;

    opacity: 0;

    &[data-iter="1"] {
      transform: translateX(-$shift);
    }

    &[data-iter="2"] {
      transform: translateX($shift);
    }

    &.layer-hint-active {
      transform: none;
      opacity: 1;
    }
  }

  .layer-hint-tail {
    transition: transform .5s ease;

    &.layer-hint-tail-block {
      transform: translateX(3.5em); // Roboto has a ratio of .5
    }

    &.layer-hint-tail-wire {
      transform: translateX(3em);
    }
  }

  .palette-stub {
    $margin: 2px;
    box-sizing: border-box;
    width: 24px - $margin * 2;
    height: 24px - $margin * 2;
    margin: $margin;

    border: rgba(255,255,255,.3) 2px solid;
    cursor: pointer;

    transition: background-color .2s ease; // This is a small region, so repaint shouldn't be that too expensive

    pointer-events: all;
  }

  // Palette holds at most 7 colors
  .palette {
    height: 25px;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    font-size: 0; // To avoid whitespaces
    $bg-overlay: rgba(255,255,255,.05);
    background: $bg linear-gradient(45deg, $bg-overlay 0% 25%, transparent 25% 50%, $bg-overlay 50% 75%, transparent 75% 100%);
    background-size: 20px 20px;

    display: flex;

    .palette-slot {
      width: 25px;
      height: 25px;
      display: inline-block;

      cursor: pointer;

      .palette-slot-mask {
        width: 100%;
        height: 100%;
        display: block;
        text-align: center;
        line-height: 25px;
        font-size: 12px;
        background: rgba(0,0,0,.3);
        color: white;

        opacity: 0;
        transition: opacity .2s ease;
      }

      &:hover .palette-slot-mask {
        opacity: 1;
      }
    }

    .palette-hint {
      line-height: 25px;
      color: white;
      opacity: .3;
      display: inline;
      flex: 1;
      font-size: 12px;
      @extend .monospace;
      text-align: right;
      padding-right: 10px;
      cursor: default;
    }
  }

  transition: transform .2s ease;
  transform: translateY(25px);
  &.palette-shown {
    transform: none;
  }
}

.build-detail-dialog {
  display: flex;
  flex-direction: column;
  padding: 0;

  width: calc(100vw - 80px);
  height: calc(100vh - 80px);

  max-width: 1080px;

  .build-detail-header {
    padding: 20px 30px 0 30px;
    box-shadow: rgba(0,0,0,.7) 0 2px 6px;
    z-index: 1;

    position: relative;

    .build-detail-tabber-container {
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;

      display: flex;

      align-items: center;
      justify-content: center;

      .tabber-click-zone {
        display: inline-flex;
        flex-direction: column;

        align-items: center;
        justify-content: center;

        cursor: pointer;

        &:hover .build-detail-tabber {
          opacity: .7;

          &.tabber-active {
            opacity: 1;
          }
        }
      }

      .tabbers {
        display: inline-flex;

        align-items: center;
        justify-content: center;
      }

      .descs {
        width: 0;
        height: 16px; // Same as line-height
        position: relative;
      }

      .build-detail-tabber {
        color: transparent;
        opacity: .38;

        transition: opacity .2s ease, background-position .5s ease;

        background-clip: text;
        background-size: 300% 100%;
        background-origin: 100% 0%;

        &.tabber-active {
          opacity: 1;
        }

        &:first-child {
          background-image: linear-gradient(-90deg, $selected-color 0 50%, white 50% 100%);
          background-position: 0% 50%;

          &.tabber-active {
            background-position: 100% 50%;
          }
        }

        &:last-child {
          background-image: linear-gradient(90deg, $selected-color 0 50%, white 50% 100%);
          background-position: 100% 50%;

          &.tabber-active {
            background-position: 0% 50%;
            opacity: 1;
          }
        }
      }

      .build-detail-tab-desc {
        @extend .monospace;
        font-size: 12px;
        line-height: 16px;
        color: white;
        letter-spacing: .3em;

        width: 100px;

        position: absolute;
        bottom: 0;
        left: -50px;

        text-align: center;

        $shift: 20px;

        opacity: 0;
        transition: opacity .5s ease, transform .5s ease;

        &:first-child {
          transform: translateX($shift);
        }

        &:last-child {
          transform: translateX(-$shift);
        }

        &.desc-active {
          transform: none;
          opacity: .38;
        }
      }
    }
  }

  .build-detail {
    position: relative;
    flex: 1;
    width: 100%;

    overflow: hidden;

    .build-detail-pane {
      position: absolute;
      left: 0;
      bottom: 0;
      right: 0;
      top: 0;

      &.centering {
        @include centering;
      }

      &.padded {
        padding: 40px;
        overflow-y: auto;
      }

      $shift: 20%;

      opacity: 0;
      transition: opacity .5s ease, transform .5s ease;
      pointer-events: none;

      &:first-child {
        transform: translateX($shift);
      }

      &:last-child {
        transform: translateX(-$shift);
      }

      &.pane-active {
        transform: none;
        opacity: 1;

        pointer-events: all;
      }
    }
  }

  .build-detail-status {
    font-size: 28px;
    color: white;
    font-weight: bold;
  }

  .build-detail-sep {
    height: 40px;
  }

  .build-detail-placeholder {
    display: inline-flex;
    padding: 40px 0;
    @include centering;
  }

  .build-detail-console {
    padding: 20px 30px;
    display: block;
    background: #121212;
    white-space: pre-wrap;
    word-break: break-word;
    color: rgba(255,255,255,.7);
    margin-top: 10px;
  }

  .build-detail-info {
    margin-top: 20px;
    font-size: 18px;
    color: rgba(255,255,255,.7);
    font-weight: 100;
  }
}

.labeled-btn {
  @include rst-input;

  display: inline-flex;
  align-items: center;
  justify-content: center;
  line-height: 44px;
  background: rgba(0,0,0,.18);
  padding-right: 15px;

  color: white;
  margin-top: 10px;
  cursor: pointer;
  margin-right: 10px;

  user-select: none;

  > span {
    opacity: .7;
    transition: opacity .2s ease;
  }

  &:hover > span {
    opacity: 1;
  }

  .material-icons, .labeled-btn-icon {
    margin-right: 15px;
    color: white;
    background: $primary-color;
    padding: 10px;
    width: 44px;
    height: 44px;
  }

  .labeled-btn-icon {
    line-height: 24px;
    font-size: 18px;
  }

  &:disabled {
    cursor: default;

    .material-icons, .labeled-btn-icon {
      filter: grayscale(1);
    }

    > span {
      opacity: .38;
    }

    &:hover > span {
      opacity: .38;
    }
  }
}


.help-dialog {
  display: flex;
  flex-direction: column;

  width: calc(100vw - 80px);
  height: auto;
  padding: 30px 40px;

  max-width: 600px;

  .help-segment-header {
    font-size: 28px;
    color: white;
    font-weight: bold;
    margin-top: 20px;
  }

  .help-body {
    color: rgba(255,255,255,.87);
    font-size: 14px;
  }

  .help-spacer {
    height: 20px;
  }

  .help-cheatsheet {
    color: white;
    column-count: 2;

    .help-shortcut {
      color: rgba(255,255,255,.54);
      font-size: 16px;
      display: flex;
      line-height: 24px;
      strong {
        display: inline-block;
        width: 100px;
        @extend .monospace;
        color: rgba(255,255,255,.87);
        margin-right: 5px;
        flex: 1;
        white-space: nowrap;
      }
    }
  }
}

.snackbar {
  $snackbar-height: 60px;
  $snackbar-margin: 20px;

  z-index: 1500; // Dialog is at 1000
  position: fixed;
  bottom: 0;
  right: $snackbar-margin;
  width: 350px;

  .snackbar-entry {
    height: $snackbar-height;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;

    box-shadow: rgba(0,0,0,.3) 0 2px 6px;

    transition: transform .2s ease;
    cursor: pointer;

    background: $bg;
    border-left: #FFC738 5px solid;

    @for $i from 1 through 10 {
      &[data-iter="#{$i}"] {
        transform: translateY(-($snackbar-height * ($i - 1) + $snackbar-margin * $i));
      }
    }
    display: flex;

    .snackbar-msg {
      flex: 1;
      padding: 10px 20px;
      height: 100%;

      color: white;
      line-height: $snackbar-height - 20px;

      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      transition: opacity .2s ease;
      display: flex;
      align-items: center;

      &:hover {
        opacity: .38;
      }
    }

    .snackbar-action {
      @include rst-input;
      padding: 20px;
      background: #222;
      color: white;
      font-weight: bold;
      cursor: pointer;
      opacity: .7;
      transition: opacity .2s ease;
      width: 100px;

      &:hover {
        opacity: 1;
      }
    }
  }
}

.settings-dialog {
  color: white;

  .user-id {
    @extend .monospace;
    font-size: 16px;
    color: rgba(255,255,255,.38);

    strong {
      color: white;
    }
  }

  .user-realname {
    font-weight: bold;
    font-size: 28px;
  }

  .user-pass {
    display: flex;
    margin-top: 20px;
    margin-bottom: 20px;

    .user-pass-input {
      flex: 1;
    }

    button {
      @include rst-input;
      width: 54px;
      height: 54px;
      background: $primary-color;
      color: white;
      display: flex;
      align-items: center;
      justify-content: center;

      cursor: pointer;

      transition: filter .2s ease;

      .material-icons {
        opacity: .7;
        transition: opacity .2s ease;
      }

      &:hover .material-icons {
        opacity: 1;
      }

      &:disabled {
        filter: grayscale(1);
        .material-icons {
          opacity: .38;
        }
      }
    }
  }

  .languages {
    margin-top: 10px;
    margin-bottom: 20px;

    display: flex;
    align-items: center;

    .language {
      display: flex;
      align-items: center;
      flex: 1;
      cursor: pointer;

      .language-ind {
        $border: 4px;

        height: 36px;
        width: 36px;
        display: flex;
        align-items: center;
        justify-content: center;

        position: relative;

        border: $primary-color $border solid;
        filter: grayscale(1);
        opacity: .3;
        transition: opacity .2s ease, filter .2s ease;

        &:after {
          content: '';
          position: absolute;
          top: -$border;
          bottom: -$border;
          left: -$border;
          right: -$border;

          background: $primary-color;
          z-index: 0;

          opacity: 0;
          transition: opacity .2s ease;
        }

        .material-icons {
          opacity: 0;
          transition: opacity .2s ease;
        }

        .material-icons {
          z-index: 1;
        }
      }

      .language-name {
        flex: 1;
        padding: 0 10px;
        line-height: 36px;

        opacity: .7;

        transition: opacity .2s ease;
      }

      &.language-selected {
        .language-ind {
          opacity: 1;
          filter: none;

          &:after {
            opacity: 1;
          }

          .material-icons {
            opacity: 1;
          }
        }

        .language-name {
          opacity: 1;
        }
      }
    }
  }
}

.about-dialog {
  color: white;

  .brand {
    font-size: 36px;
    text-align: center;
    color: rgba(255,255,255,.5);

    strong {
      color: rgba(255,255,255,.7);
    }
  }

  .ref {
    font-size: 12px;
    font-weight: 100;
    @extend .monospace;
    text-align: center;
    color: rgba(255,255,255,.38);
  }

  .hint {
    margin-top: 20px;
  }

  a {
    color: inherit;
    text-decoration: underline;
    text-decoration-color: rgba(255,255,255,.38);

    transition: opacity .2s ease;

    &:hover {
      opacity: .7;
    }
  }
}

.drop-layer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  z-index: 1000;

  background: black;
  pointer-events: none;

  opacity: 0;

  transition: opacity .2s ease;

  color: white;

  font-size: 18px;

  .material-icons {
    font-size: 56px;
  }

  &.drop-layer-shown {
    opacity: .3;
  }
}

.monospace {
  font-family: 'Roboto Mono', monospace;
}

.spacer {
  width: 0;
  margin-right: 5px;
}

.sep {
  @extend .monospace;
  color: rgba(255,255,255,.18);
  margin: 0 10px;

  user-select: none;
}

hr {
  margin: 20px 20px;
  border-color: rgba(255,255,255,.3);
}
