.tooltip-container {
  position: relative;
  display: inline-block;

  &:hover {
    z-index: 1000;
  }
}

.tooltip-hover {
  position: relative;
  z-index: 3;
}

.tooltip {
  position: absolute;
  pointer-events: none;

  z-index: 2;

  top: 0px;
  right: 100%;
  width: 200px;
  height: auto;
  min-height: 100%;
  min-width: 100%;

  background: rgba(0,0,0,.9);

  opacity: 0;
  transform: translateX(20px);

  transition: opacity .2s ease, transform .2s ease;

  .tooltip-hover:hover + &:not(.tooltip-disabled) {
    opacity: 1;
    transform: translateX(0px);

    &.tooltip-gap {
      transform: translateX(-10px);
    }
  }

  padding: 15px 20px;
  display: inline-flex;
  align-items: center;

  color: rgba(255,255,255, .7);
  font-size: 12px;
  line-height: 12px;
  border-right: change-color($primary-color, $alpha: 0.5) 5px solid;
  @extend .monospace;
}
