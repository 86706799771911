@import url("https://fonts.loli.net/icon?family=Material+Icons");
@import url("https://fonts.loli.net/css?family=Roboto+Mono:100,400,700i&display=swap");
@import url("https://fonts.loli.net/css?family=Roboto:100,400,700i&display=swap");

@font-face {
    font-family: 'Segment7Standard';
        src: url(../font/segment7/Segment7Standard.otf) format('opentype');
    font-weight: normal;
    font-style: normal;
}
