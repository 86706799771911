$warning: #D4CE24;
$confirm: #29C2BD;
$error: #CB4042;

.unassigned-signal-line {
  background: fade-out($warning, 0.9);
}

.unassigned-signal-glyph {
  background: $warning;
}

.assigned-signal-glyph {
  background: fade-out($confirm, 0.5);
}

.top-line {
  background: fade-out($confirm, 0.9);
}

.top-glyph {
  background: $confirm;
}

.diagnostic-error {
  text-decoration: underline;
  text-decoration-thickness: 2px;
  // text-decoration-style: wavy;
  text-decoration-color: $error;
  text-decoration-skip-ink: none;
  background: fade-out($error, 0.8);
}

.diagnostic-warning {
  text-decoration: underline;
  // text-decoration-style: wavy;
  text-decoration-color: $warning;
  text-decoration-skip-ink: none;
}

.diagnostic-info {
  text-decoration: underline;
  // text-decoration-style: wavy;
  text-decoration-color: $confirm;
  text-decoration-skip-ink: none;
}
