$hl: #ffeb3b;

.help {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  padding: 40px;

  pointer-events: none;

  z-index: 120;

  color: white;
  letter-spacing: 0.05em;
}

.help .brand {
  font-size: 48px;
  color: white;
  line-height: 48px;

  strong {
    font-size: inherit;
    display: inline-block;
    line-height: inherit;
  }
}

.help-backdrop {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  z-index: 0;

  background: rgba(0,0,0,.3);
  opacity: 0;
  transition: opacity .2s ease, transform .2s ease;

  .help-backdrop-stripe {
    $overlay: rgba(0,0,0,.18);
    background-image: linear-gradient(45deg, $overlay 0% 25%, transparent 25% 50%, $overlay 50% 75%, transparent 75% 100%);
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    background-size: 100px 100px;

    opacity: 0;
    transition: opacity .2s ease;
  }

  &.help-backdrop-shown {
    opacity: 1;
    pointer-events: all;
  }

  &.help-backdrop-editor-only {
    transform: translateX(-50vw);
    .help-backdrop-stripe {
      opacity: 1;
    }
  }

  &.help-backdrop-sandbox-only {
    transform: translateX(50vw);
    .help-backdrop-stripe {
      opacity: 1;
    }
  }
}

.help-controller-cont {
  position: fixed;

  right: 0;
  width: 60px;
  top: 0;
  bottom: 0;

  display: flex;
  align-items: center;

  transform: translateX(60px + 12px);
  transition: transform .2s ease;

  z-index: 120;
}

.help-controller {
  flex: 1;
  height: auto;
  background: $bg;
  box-shadow: rgba(0,0,0,.3) 0 4px 12px;
  display: inline-flex;
  flex-direction: column;

  pointer-events: all;
}

.help-open .help-controller-cont {
  transform: none;
}

.help-action {
  height: 60px;
  width: 60px;
  line-height: 60px;
  text-align: center;
  color: white;

  cursor: pointer;

  opacity: .7;
  transition: opacity .2s ease;

  &:hover {
    opacity: 1;
  }

  &.help-action-disabled {
    cursor: default;
    opacity: .18;
  }
}

.help-action-primary {
  background: $primary-color;
  transition: filter .2s ease;

  &.help-action-primary-disabled {
    filter: grayscale(1) brightness(0.7);
  }
}

.help-content {
  transition: opacity .2s ease;

  &.help-content-hidden {
    opacity: 0;
  }
}

.help del {
  margin: 0 5px;
  color: rgba(255,255,255,.3);
  text-decoration-color: white;
}

.help p {
  margin-bottom: 0;
  color: rgba(255,255,255,.7);
}

.help-welcome {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .help-welcome-inner {
    padding: 30px 40px;
    background: rgba(0,0,0,1);
  }

  .help-welcome-desc {
    margin-top: 40px;
    max-width: 400px;
  }

  .help-welcome-icons {
    margin-top: 40px;
    display: flex;
    align-items: center;
    justify-content: center;

    .help-welcome-icon {
      font-size: 12px;
      color: rgba(255,255,255,.54);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .material-icons {
        color: white;
      }
    }
  }
}

.help-box {
  max-width: 400px;
  padding: 30px 40px;

  background: rgba(0,0,0,1);
  z-index: 1;

  strong {
    display: block;
    font-size: 32px;
    line-height: 32px;
  }
}

.help-layout {
  .help-layout-base {
    display: flex;
    position: absolute;
    top: 60px;
    bottom: 0;
    right: 0;
    left: 0;

    .help-layout-half {
      flex: 1;
      height: 100%;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;

      padding: 20px;
    }
  }

  .help-layout-toolbar {
    position: absolute;
    top: 60px;
    left: calc(50vw - 60px);

    .help-layout-row {
      height: 60px;
      display: flex;

      .material-icons {
        width: 60px;
        height: 60px;

        display: inline-flex;
        align-items: center;
        justify-content: center;

        border: white 1px dashed;
      }

      .help-layout-desc {
        display: inline-flex;
        height: 60px;
        align-items: center;
        padding: 0 20px;
        background: rgba(0,0,0,1);
      }
    }
  }

  .help-layout-build {
    position: absolute;
    top: 0;
    right: 70px;
    height: 60px;
    width: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: white 1px dashed;
    background: rgba(0,0,0,1);
  }
}

.help .help-hl {
  color: $hl;
}
